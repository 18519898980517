import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { init } from '@api/monterosa/init'
import {
  answer as sdkAnswer,
  onElementUpdated,
  onElementResults,
  getElements,
} from '@monterosa-sdk/interact-kit'
import data from '@data/config'
import { getCurrentDatePST } from '@utils'

const VoteApiContext = createContext()
const useVoteApiContext = () => useContext(VoteApiContext)
const VoteApiConsumer = VoteApiContext.Consumer

const VoteApiProvider = ({ children }) => {
  const { monterosa } = data
  const {
    host,
    projectId,
    judgesProjectId,
    productionProjectId,
    productionJudgesId,
  } = monterosa

  const [sdk, setSdk] = useState(null)
  const [formMeta, setFormMeta] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [elements, setElements] = useState([])
  const [votedCount, setVotedCount] = useState(0)
  const [isDoneVoting, setIsDoneVoting] = useState(false)
  const [isRevote, setIsRevote] = useState('')
  const [accessCode, setAccessCode] = useState('')
  const [isJudge, setIsJudge] = useState(false)
  const [events, setEvents] = useState(null)
  const [activeEvent, setActiveEvent] = useState(null)
  const [oldEvent, setOldEvent] = useState(null)
  const [oldElements, setOldElements] = useState(null)
  const [prevVotes, setPrevVotes] = useState([])
  const [currentForm, setCurrentForm] = useState(null)
  const unsubscribeRef = useRef(null)

  // Updates when a new vote is cast or element updates
  const handleUpdate = (updatedElement) => {
    setElements((prev) =>
      prev.map((el) => (el.id === updatedElement.id ? updatedElement : el))
    )
  }

  const fetchForm = async (formService, id) => {
    const url = `${formService}/form/${id}.json`

    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()
    if (data) {
      setCurrentForm(data)
    }
  }

  // Track votes and determine if done
  useEffect(() => {
    const newCount = elements.reduce((count, el) => {
      const answers = el?.userAnswer?._answers
      if (!answers) return count
      const hasVoted = [...answers.values()].some((v) => v === 1)
      return hasVoted ? count + 1 : count
    }, 0)

    setVotedCount(newCount)
    setIsDoneVoting(newCount === 24)
  }, [elements])

  // Initialize SDK + Event Data
  useEffect(() => {
    const voterTypeFromPath =
      typeof window !== 'undefined' &&
      window.location.pathname.startsWith('/judge-vote')
        ? 'judge'
        : 'fan'
    localStorage.setItem('voterType', voterTypeFromPath)
    const isJudgeFlow = voterTypeFromPath === 'judge'
    setIsJudge(isJudgeFlow)

    const initialize = async () => {
      try {
        const {
          sdk: initializedSdk,
          project,
          activeEvent,
          events,
          elements: fetchedElements,
          unsubscribeOnEventAdded,
          oldEvent,
          oldElements,
        } = await init(
          host,
          isJudgeFlow ? productionJudgesId : productionProjectId,
          (newEvent, newElements) => {
            setFormMeta((prev) => ({
              ...prev,
              eventId: newEvent?.id,
              eventName: newEvent?.name,
            }))
            setElements(newElements)
          }
        )

        setSdk(initializedSdk)
        setElements(fetchedElements)
        setEvents(events)
        setOldEvent(oldEvent)
        setOldElements(oldElements)
        setActiveEvent(activeEvent)

        // Attach event listeners
        const validElements = fetchedElements.filter((el) => el?.timeline)
        validElements.forEach((el) => {
          onElementUpdated(el, handleUpdate)
          onElementResults(el, handleUpdate)
        })

        // Prepare form meta
        const formService = project.extensions?.submissions?.find(
          (item) => item.name === 'webservice'
        )?.data

        if (!formService) throw new Error('Webservice URL not found')

        const currentFormId = activeEvent?.fields?.formId?.data?.[0]?.id
        const eventId = activeEvent?.id
        const eventName = activeEvent?.name
        // Fetch form data from the form service

        fetchForm(formService, currentFormId)
        // Check for new event (reset login)
        const storedFormData = JSON.parse(
          localStorage.getItem('formData') || '{}'
        )
        const lastSavedEventId = storedFormData?.eventId
        const isNewEvent = eventId && eventId !== lastSavedEventId

        if (isNewEvent) {
          setIsLoggedIn(false)
          localStorage.setItem(
            'formData',
            JSON.stringify({ ...storedFormData, eventId })
          )
        }

        setFormMeta({
          formService,
          currentFormId,
          eventId,
          eventName,
          projectId: isJudgeFlow ? productionJudgesId : productionProjectId,
        })

        unsubscribeRef.current = unsubscribeOnEventAdded
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    initialize()
    return () => unsubscribeRef.current?.()
  }, [])

  // 🕒 Login check based on date + event ID
  useEffect(() => {
    const raw = localStorage.getItem('formData')
    if (!raw) return

    const stored = JSON.parse(raw)
    const currentDate = getCurrentDatePST()
    const voteDateMatches = stored?.lastVoteDate === currentDate
    const eventMatches =
      !formMeta?.eventId || formMeta?.eventId === stored?.eventId

    setIsLoggedIn(voteDateMatches && eventMatches)
  }, [formMeta])

  // 🧾 Load old vote history
  useEffect(() => {
    if (!sdk || !oldElements) return
    const lastVotes = oldElements.reduce((acc, el) => {
      const categoryId = el?.question?.categoryId
      const answersMap = el?.userAnswer?._answers
      const selectedOption = [...(answersMap?.entries() || [])].find(
        ([, v]) => v === 1
      )?.[0]
      if (categoryId && selectedOption !== undefined) {
        acc.push({ categoryId, userAnswer: selectedOption })
      }
      return acc
    }, [])
    setPrevVotes(lastVotes)
  }, [sdk, oldElements])

  // 🔁 Answer handler
  const contextAnswer = async (element, optionIndex) => {
    if (!sdk || !activeEvent) return
    try {
      await sdkAnswer(element, { option: optionIndex, value: 1 })
      const refreshed = await getElements(activeEvent)
      setElements(refreshed)
    } catch (err) {
      console.error('Vote failed:', err)
    }
  }

  const getUserAnswerForCategory = (categoryId) => {
    const el = elements.find((el) => el?.question?.categoryId === categoryId)
    const entries = el?.userAnswer?._answers?.entries?.() || []
    for (const [key, value] of entries) {
      if (value === 1) return key
    }
    return null
  }

  const getAllUserAnswers = () =>
    elements
      .map((el) => {
        const categoryId = el?.question?.categoryId
        const userAnswer = getUserAnswerForCategory(categoryId)
        return userAnswer !== null ? { categoryId, userAnswer } : null
      })
      .filter(Boolean)

  const hasUserVotedAnywhere = () =>
    elements.some((el) =>
      [...(el?.userAnswer?._answers?.values() || [])].some((v) => v === 1)
    )

  const state = {
    formMeta,
    isLoading,
    error,
    isLoggedIn,
    setIsLoggedIn,
    contextAnswer,
    elements,
    getUserAnswerForCategory,
    getAllUserAnswers,
    hasUserVotedAnywhere,
    isRevote,
    setIsRevote,
    accessCode,
    setAccessCode,
    votedCount,
    setVotedCount,
    isDoneVoting,
    setIsDoneVoting,
    isJudge,
    oldEvent,
    oldElements,
    prevVotes,
    setPrevVotes,
    currentForm,
    setCurrentForm,
  }

  return (
    <VoteApiContext.Provider value={state}>{children}</VoteApiContext.Provider>
  )
}

export { VoteApiConsumer, VoteApiProvider, useVoteApiContext }
