exports.components = {
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-howitworks-js": () => import("./../../../src/pages/howitworks.js" /* webpackChunkName: "component---src-pages-howitworks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-judge-vote-js": () => import("./../../../src/pages/judge-vote.js" /* webpackChunkName: "component---src-pages-judge-vote-js" */),
  "component---src-pages-judges-js": () => import("./../../../src/pages/judges.js" /* webpackChunkName: "component---src-pages-judges-js" */),
  "component---src-pages-offer-terms-js": () => import("./../../../src/pages/offer-terms.js" /* webpackChunkName: "component---src-pages-offer-terms-js" */),
  "component---src-pages-pastwinners-js": () => import("./../../../src/pages/pastwinners.js" /* webpackChunkName: "component---src-pages-pastwinners-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-talent-js": () => import("./../../../src/pages/talent.js" /* webpackChunkName: "component---src-pages-talent-js" */),
  "component---src-pages-vote-js": () => import("./../../../src/pages/vote.js" /* webpackChunkName: "component---src-pages-vote-js" */),
  "component---src-pages-winners-js": () => import("./../../../src/pages/winners.js" /* webpackChunkName: "component---src-pages-winners-js" */),
  "component---src-templates-category-view-js": () => import("./../../../src/templates/category-view.js" /* webpackChunkName: "component---src-templates-category-view-js" */)
}

