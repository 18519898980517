export const hasTouchScreen = () => {
  let hasTouchScreen = false

  if (typeof window !== 'undefined') {
    const navigator = window.navigator
    if ('maxTouchPoints' in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0
    } else if ('msMaxTouchPoints' in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0
    } else {
      const mQ = window.matchMedia && matchMedia('(pointer:coarse)')
      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches
      } else if ('orientation' in window) {
        hasTouchScreen = true
      } else {
        const UA = navigator.userAgent
        hasTouchScreen = /Mobi/i.test(UA)
      }
    }
  }
  return hasTouchScreen
}
