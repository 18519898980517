import React from 'react'
import { SessionProvider } from 'cr-static-shared-components'

import { AdProvider } from '@contexts/AdContext'
import { DateProvider } from '@contexts/DateContext'
import { ResetProvider } from '@contexts/ResetContext'
import { VoteProvider } from '@contexts/VoteContext'
import { VoteApiProvider } from '../contexts/VoteApiContext'

const wrapRoot = ({ element }) => (
  <SessionProvider>
    <DateProvider>
      <ResetProvider>
        <VoteApiProvider>
          <VoteProvider>
            <AdProvider>{element}</AdProvider>
          </VoteProvider>
        </VoteApiProvider>
      </ResetProvider>
    </DateProvider>
  </SessionProvider>
)

export default wrapRoot
