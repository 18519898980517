import { configure } from '@monterosa-sdk/core'
import {
  getProject,
  getEvents,
  getEvent,
  getElements,
  onEventAdded,
  getEventMemoized,
} from '@monterosa-sdk/interact-kit'

export async function init(host, projectId, onEventUpdate) {
  const sdk = configure({ host, projectId })

  try {
    const project = await getProject(sdk)
    let events = await getEvents(project)

    let latestEventId = null
    let latestTimestamp = 0

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)

      if (key.startsWith('form-')) {
        try {
          const raw = localStorage.getItem(key)
          const data = JSON.parse(raw)
          const voteDate = new Date(data?.lastVoteDate)
          if (!isNaN(voteDate)) {
            const timestamp = voteDate.getTime()

            if (timestamp > latestTimestamp) {
              const parts = key.split('-')
              if (parts.length >= 3) {
                const eventIdParts = parts.slice(1, -1)
                latestEventId = eventIdParts.join('-')
                latestTimestamp = timestamp
              }
            }
          }
        } catch (e) {
          console.warn(`Invalid localStorage value for key: ${key}`, e)
        }
      }
    }

    const oldEvent = latestEventId
      ? await getEventMemoized(latestEventId)
      : null

    const oldElements = oldEvent ? await getElements(oldEvent) : []

    const sortedEvents = events.sort((a, b) => b.startAt - a.startAt)
    const activeEvent = sortedEvents.find((event) => event.state === 'active')
    const event = activeEvent ? await getEvent(activeEvent.id) : null
    const elements = event ? await getElements(event) : []

    const unsubscribeOnEventAdded = onEventAdded(project, async (newEvent) => {
      events = [...events, newEvent].sort((a, b) => b.startAt - a.startAt)
      const refreshed = events.find((e) => e.state === 'active')
      if (!refreshed) return
      const refreshedEvent = await getEvent(refreshed.id)
      const refreshedElements = await getElements(refreshedEvent)
      onEventUpdate?.(refreshedEvent, refreshedElements)
    })

    return {
      sdk,
      project,
      activeEvent: event,
      events: sortedEvents,
      elements,
      unsubscribeOnEventAdded,
      oldEvent,
      oldElements,
    }
  } catch (error) {
    console.error('Error initializing Monterosa SDK:', error)
    throw error
  }
}
